.workOrderListBox .workOrderList {
  position: absolute;
  bottom: 0.266667rem;
  left: 0;
  right: 0;
  top: 0.533333rem;
}

.workOrderListBox .workOrderListBoxTabs {
  margin-bottom: 0.266667rem;
}

.workOrderListBox .workOrderListBoxTabs ul {
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  padding: 0 0.92rem;
}

.workOrderListBox .workOrderListBoxTabs ul li {
  font-family: PingFangSC-Regular;
  font-size: 0.373333rem;
  color: #333;
  text-align: center;
  height: 1.2rem;
  line-height: 1.2rem;
}

.workOrderListBox .workOrderListBoxTabs ul li.active {
  color: #0092ff;
  position: relative;
}

.workOrderListBox .workOrderListBoxTabs ul li.active::after {
  position: absolute;
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 0.08rem;
  background-color: #0092ff;
  border-radius: 0.04rem;
  content: " ";
}