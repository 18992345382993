.stepBox ul li {
  font-family: PingFangSC-Regular;
  font-size: 0.32rem;
  color: #999;
  display: flex;
  margin-bottom: 1.173333rem;
}

.stepBox ul li i {
  background: rgba(255,0,0,.1);
  border: 0.053333rem solid red;
  position: relative;
}

.stepBox ul li i::after {
  position: absolute;
  left: 0.106667rem;
  width: 0.026667rem;
  top: 0.266667rem;
  height: 1.76rem;
  content: " ";
  background-color: #e6e6e6;
}

.stepBox ul li i {
  display: block;
  width: 0.32rem;
  height: 0.32rem;
  background: rgba(230,230,230,.1);
  border: 0.053333rem solid #e6e6e6;
  border-radius: 50% 50%;
  margin-right: 0.266667rem;
  margin-left: 0.533333rem;
}

.stepBox ul li p span {
  display: block;
}

.stepBox ul li p span:last-child {
  margin-top: 0.133333rem;
}

.stepBox ul li:last-of-type>span {
  color: #07c85c;
}

.stepBox ul li:last-of-type i {
  background: rgba(7,200,92,.1);
  border: 0.053333rem solid #07c85c;
}

.stepBox ul li:last-of-type i::after {
  display: none;
}

.stepBox ul li:first-of-type>span {
  color: red;
}

.stepBox ul li:first-of-type i {
  background: rgba(255,0,0,.1);
  border: 0.053333rem solid red;
}