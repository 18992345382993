.liftLocationBox .liftLocationBoxFoot {
  z-index: 10000;
  position: fixed;
  padding: 0.333333rem 0.133333rem;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}

.liftLocationBox .liftLocationBoxFoot span {
  height: 1.2rem;
  display: block;
  margin: 0 0.133333rem;
  flex: 1 1;
  line-height: 1.2rem;
  font-family: PingFangSC-Regular;
  font-size: 0.48rem;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  background-color: #6dd400;
  border-radius: 0.133333rem;
}

.liftLocationBox .liftLocationBoxFoot span:first-of-type {
  background-color: #ccc;
  color: #000;
}

.liftLocationBox .liftLocationBoxFoot span:last-of-type {
  background-color: #0092ff;
}