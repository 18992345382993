.myLiftDetailBox {
  background-color: #fff;
  overflow-y: auto;
}

.myLiftDetailBox .coverBox {
  height: 0.266667rem;
  background: #f8f8f8;
}

.myLiftDetailBox h3 {
  padding: 0.4rem;
  padding-bottom: 0;
  padding-left: 0.533333rem;
  font-family: PingFangSC-Medium;
  color: #333;
  position: relative;
  margin-bottom: 0.4rem;
}

.myLiftDetailBox h3:after {
  width: 0.053333rem;
  content: " ";
  height: 0.373333rem;
  background: #0092ff;
  position: absolute;
  left: 0.4rem;
  top: 0.4rem;
}

.myLiftDetailBox ul {
  padding: 0 0.4rem;
  padding-bottom: 0.4rem;
}

.myLiftDetailBox ul li {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.266667rem;
}

.myLiftDetailBox ul li span {
  font-family: PingFangSC-Regular;
  color: #999;
}

.myLiftDetailBox ul li span:first-child {
  color: #333;
  margin-right: 0.533333rem;
  min-width: 1.493333rem;
}