.headerBox .am-navbar {
  height: 1.173333rem;
}

.headerBox .am-navbar-left {
  padding-left: 0.32rem;
}

.headerBox .am-navbar-right {
  padding-right: 0.32rem;
}

.headerBox .am-navbar-left-icon img {
  height: 0.426667rem;
  width: 0.249333rem;
  display: block;
}

.headerBox .am-navbar-title {
  font-family: PingFangSC-Medium;
  font-size: 0.453333rem;
  color: #333;
  text-align: center;
}

.headerBox .rightSpan {
  font-family: PingFangSC-Regular;
  font-size: 0.4rem;
  color: #0092ff;
  text-align: center;
}