.maintenanceCalendar {
  position: relative;
}

.maintenanceCalendar .typeList {
  display: flex;
  padding: 0 0.56rem;
}

.maintenanceCalendar .typeList li {
  margin-right: 0.4rem;
  padding: 0.066667rem 0.266667rem;
  border: 0.026667rem solid #6236ff;
  border-radius: 0.28rem;
  border-radius: 0.28rem;
}

.maintenanceCalendar .typeList li:last-child {
  margin-right: 0;
}

.maintenanceCalendar .typeList .plan {
  border-color: #6236ff;
  color: #6236ff;
}

.maintenanceCalendar .typeList .maintain {
  border-color: #32c5ff;
  color: #32c5ff;
}

.maintenanceCalendar .typeList .service {
  border-color: #fa6400;
  color: #fa6400;
}

.maintenanceCalendar .main {
  position: absolute;
  left: 0;
  right: 0;
  top: 2.666667rem;
  bottom: 0;
  margin-top: 0.4rem;
  padding: 0 0.4rem;
}

.maintenanceCalendar .main .maintenanceCalendarScrollBox {
  position: absolute;
  left: 0;
  right: 0;
  top: 0.8rem;
  bottom: 0;
}

.maintenanceCalendar .main ul {
  display: flex;
  align-items: center;
}

.maintenanceCalendar .main ul li {
  font-family: PingFangSC-Regular;
  color: #333;
  margin-right: 0.533333rem;
}

.maintenanceCalendar .main ul .active {
  font-size: 0.4rem;
  color: #0092ff;
}

.maintenanceCalendar .drawerMainBottomIn {
  top: 10% !important;
}

.maintenanceCalendar .popuoBox {
  padding-top: 0.4rem;
  height: 100%;
}

.maintenanceCalendar .faultPointIdBox {
  width: 100%;
  height: 100%;
}