.dialogBox {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.3);
  padding-top: 5.333333rem;
  z-index: 99999;
}

.dialogBox .main {
  width: 80%;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 0.133333rem;
  overflow: hidden;
}

.dialogBox .main h1 {
  text-align: center;
  font-size: 0.48rem;
  border-bottom: 0.013333rem solid #ccc;
  margin: 0;
  padding: 0.266667rem 0;
  font-weight: normal;
}

.dialogBox .main p {
  word-break: break-all;
  font-size: 0.373333rem;
  padding: 0.266667rem;
  min-height: 2rem;
}

.dialogBox .main .dialog-foot {
  background: #fff;
  box-shadow: 0 0 0.266667rem 0 rgba(0,146,255,.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialogBox .main .dialog-foot div {
  width: 50%;
  height: 1.066667rem;
  background: #fff;
  line-height: 1.066667rem;
  font-family: PingFangSC-Regular;
  font-size: 0.48rem;
  color: #0092ff;
  letter-spacing: 0;
  text-align: center;
}

.dialogBox .main .dialog-foot div:last-of-type {
  background: #0092ff;
  color: #fff;
}