.selectBox {
  width: 100%;
  position: relative;
}

.selectBox input {
  height: 0.666667rem;
  font-size: 0.32rem;
  padding: 0 0.133333rem;
  width: 100%;
}

.selectBox .selectOptionBox ul {
  padding: 0;
  position: absolute;
  top: 0.8rem;
  left: 0;
  right: 0;
  background-color: #fff;
  border: none;
  z-index: 1000;
  height: 0;
  overflow-y: auto;
  transition: height .3s ease-in-out;
}

.selectBox .selectOptionBox ul li {
  padding: 0;
  margin: 0;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  display: block;
}

.selectBox .selectOptionBox ul li:hover {
  background-color: #0092ff;
  color: #ccc;
}

.selectBox .selectOptionBox ul li.chooseActive {
  background-color: #0092ff;
  color: #eee;
}

.selectBox .selectOptionBox ul .noData {
  display: block;
  text-align: center;
}

.selectBox .selectOptionBox ul.selectOptions {
  height: 3.333333rem;
  border: 0.013333rem solid #ccc;
  display: block !important;
  margin-left: 0;
}