.tabs .tabsItem {
  font-family: PingFangSC-Regular;
  font-size: 0.373333rem;
  color: #333;
  text-align: center;
  padding: 0.333333rem 0;
}

.tabs .tabsItem .active {
  color: #0092ff;
  position: relative;
}

.tabs .tabsItem .active::after {
  position: absolute;
  content: "";
  background: #0092ff;
  border-radius: 0.04rem;
  height: 0.08rem;
  left: 0;
  right: 0;
  bottom: -0.253333rem;
}