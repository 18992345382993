.employees {
  background-image: url(../../static/images/bg.png);
  background-size: 100% 100%;
}

.employees .employeesBox {
  padding: 0 0.4rem;
  position: fixed;
  top: 1.173333rem;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: hidden;
}

.employees .employeesHeader {
  padding: 0 0.4rem;
  height: 1.173333rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.employees .employeesHeader span {
  flex: 1 1;
  font-size: 0.453333rem;
  font-family: PingFang SC;
  color: #fff;
  font-weight: 500;
  margin-left: 0.213333rem;
}

.employees .employeesHeader img {
  display: block;
}

.employees .employeesHeader .employeesHeaderLiftImg {
  width: 0.413333rem;
  height: 0.44rem;
}

.employees .employeesHeader .employeesHeaderRightImg {
  width: 0.493333rem;
  height: 0.106667rem;
}

.employees .employeesBox {
  display: flex;
  margin-top: 0.266667rem;
}

.employees .employeesBox .employeesBoxTime {
  display: flex;
  align-items: flex-end;
}

.employees .employeesBox .employeesBoxTime .employeesBoxTimeSmall {
  display: block;
  background-image: url(../../static/images/rectangular.png);
  background-size: 100% 100%;
  width: 0.933333rem;
  height: 1.226667rem;
  line-height: 1.226667rem;
  text-align: center;
  font-size: 0.68rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fff;
  text-shadow: 0 0 0.093333rem rgba(6,11,74,.4);
}

.employees .employeesBox .employeesBoxTime .employeesBoxTimeSmall:nth-of-type(1) {
  margin-left: 0;
}

.employees .employeesBox .employeesBoxTime .employeesBoxTimeSmall:nth-of-type(5) {
  margin-left: 0.213333rem;
}

.employees .employeesBox .employeesBoxTime .employeesBoxTimeSmall:nth-of-type(7) {
  margin-left: 0.24rem;
}

.employees .employeesBox .employeesBoxTime .employeesBoxTimeSmall:nth-of-type(7),
.employees .employeesBox .employeesBoxTime .employeesBoxTimeSmall:nth-of-type(8) {
  width: 1.6rem;
  height: 2.106667rem;
  font-size: 1.773333rem;
  line-height: 2.106667rem;
}

.employees .employeesBox .employeesBoxContent {
  margin-top: 0.293333rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fff;
}

.employees .employeesFooter {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 10.053333rem;
  overflow-y: auto;
  padding-bottom: 1.333333rem;
}

.employees .employeesFooter .employeesFooterClose {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1.173333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0.013333rem solid rgba(255,255,255,.3);
}

.employees .employeesFooter .employeesFooterClose img {
  display: block;
  width: 0.413333rem;
  height: 0.44rem;
}

.employees .employeesFooter ul {
  display: flex;
  flex-wrap: wrap;
}

.employees .employeesFooter ul li {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-left: 0.586667rem;
  align-items: center;
  margin-bottom: 0.573333rem;
}

.employees .employeesFooter ul li span {
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fff;
  margin-top: 0.32rem;
}

.employees .employeesFooter ul li img {
  width: 2.36rem;
  height: 2.36rem;
  display: block;
}