#itemBoxMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0.4rem;
}

#itemBoxMain .itemBox {
  width: 100%;
  background: #fff;
  box-shadow: 0 0.053333rem 0.106667rem 0 rgba(0,0,0,.1);
  border-radius: 0.133333rem;
  border-radius: 0.133333rem;
  margin: 0;
  padding-top: 0.293333rem;
  padding-bottom: 0.4rem;
  margin-bottom: 0.4rem;
}

#itemBoxMain .itemBox .itemBoxTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.266667rem;
  padding-bottom: 0.28rem;
  border-bottom: 0.013333rem solid #e6e6e6;
}

#itemBoxMain .itemBox .itemBoxTop .itemBoxTopLeft {
  display: flex;
  align-items: center;
}

#itemBoxMain .itemBox .itemBoxTop .itemBoxTopLeft .orderBox span {
  font-size: 0.32rem;
}

#itemBoxMain .itemBox .itemBoxTop .itemBoxTopLeft img {
  margin-right: 0.133333rem;
  display: block;
  width: 0.316rem;
  height: 0.368rem;
}

#itemBoxMain .itemBox .itemBoxTop .itemBoxTopLeft span {
  font-family: PingFangSC-Medium;
  font-size: 0.373333rem;
  color: #333;
  letter-spacing: 0;
}

#itemBoxMain .itemBox .itemBoxTop .itemBoxTopRight {
  font-size: 0.32rem;
}

#itemBoxMain .itemBox .itemBoxTop .itemBoxTopRight4 {
  color: #6dd400;
}

#itemBoxMain .itemBox .itemBoxBottom {
  padding-top: 0.4rem;
  font-family: PingFangSC-Regular;
  font-size: 0.346667rem;
  letter-spacing: 0;
}

#itemBoxMain .itemBox .itemBoxBottom .position {
  color: #333;
  margin-left: 0.693333rem;
  margin-right: 0.346667rem;
}

#itemBoxMain .itemBox .itemBoxBottom .positionDetail {
  color: #999;
}