* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  position: relative;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.appBox {
  font-size: 0.32rem;
  height: 100%;
}

.appBox>div {
  width: 100%;
  height: 100%;
}

.appBox .am-list-item .am-input-control input {
  font-size: 0.373333rem;
}

.appBox .am-button {
  height: 0.88rem;
  line-height: 0.88rem;
  font-size: 0.373333rem;
}

.appBox .am-button-primary {
  border-radius: 0;
}

.appBox .am-image-picker-list {
  padding-left: 0;
  padding-right: 0;
}

.commonBodyBox {
  height: 100%;
}

.am-picker-popup-item {
  font-size: 0.373333rem !important;
  height: 0.853333rem !important;
}

.am-picker-col-item-selected {
  font-size: 0.373333rem !important;
}

.am-toast-notice-content .am-toast-text {
  font-size: 0.373333rem !important;
}