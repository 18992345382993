.useUnit {
  padding: 0.4rem;
}

.useUnit .useUnitItem {
  background: #fff;
  box-shadow: 0 0.053333rem 0.106667rem 0 rgba(0,0,0,.1);
  border-radius: 0.133333rem;
  border-radius: 0.133333rem;
  padding: 0.2rem;
}

.useUnit .useUnitItem p {
  padding: 0.2rem;
  font-family: PingFangSC-Regular;
  font-size: 0.346667rem;
  color: #333;
  letter-spacing: 0;
}

.useUnit .useUnitItem p span {
  margin-right: 0.133333rem;
}

.useUnit .useUnitItem p span:nth-of-type(2) {
  color: #999;
}

.useUnit .useUnitItem .useUnitPhone a {
  color: #0092ff;
}

.useUnit .useUnitPrompt {
  margin-top: 0.4rem;
  color: #999;
}

.useUnit .useUnitPrompt h5 {
  margin-bottom: 0.133333rem;
  font-size: 0.32rem;
}

.useUnit .useUnitPrompt p {
  margin-bottom: 0.133333rem;
}