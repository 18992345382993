.tourists .touristsBox {
  padding: 0 0.4rem;
  position: fixed;
  top: 0.4rem;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

.tourists .touristsHeader {
  padding: 0 0.4rem;
  height: 1.173333rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

.tourists .touristsHeader span {
  flex: 1 1;
  font-size: 0.453333rem;
  font-family: PingFang SC;
  color: #282828;
  font-weight: 500;
  margin-left: 0.213333rem;
}

.tourists .touristsHeader img {
  display: block;
}

.tourists .touristsHeader .touristsHeaderLiftImg {
  width: 0.413333rem;
  height: 0.44rem;
}

.tourists .touristsHeader .touristsHeaderRightImg {
  width: 0.493333rem;
  height: 0.106667rem;
}

.tourists .touristsBI {
  height: 5.16rem;
  position: relative;
}

.tourists .touristsBI img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 0.36rem;
}

.tourists .touristsBI span {
  font-size: 0.373333rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #fff;
  position: absolute;
  left: 0.466667rem;
  bottom: 0.28rem;
}

.tourists .touristsDetail {
  margin: 0 -0.4rem;
  margin-top: 0.933333rem;
}

.tourists .touristsDetail:nth-of-type(3) {
  margin-top: 0.346667rem;
}

.tourists .touristsDetail h1 {
  font-size: 0.626667rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: #282828;
  margin: 0;
  margin-left: 0.506667rem;
  margin-bottom: 0.853333rem;
}

.tourists .touristsDetail ul {
  display: flex;
  flex-wrap: wrap;
}

.tourists .touristsDetail ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0.4rem;
  margin-bottom: 0.8rem;
}

.tourists .touristsDetail ul li img {
  display: block;
  width: 0.88rem;
  height: 0.946667rem;
}

.tourists .touristsDetail ul li:nth-of-type(4) {
  margin-right: 0;
}

.tourists .touristsDetail ul li span {
  display: block;
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #282828;
  margin-top: 0.4rem;
}

.am-modal-transparent {
  width: 7.6rem;
}

.am-modal-title {
  font-size: 0.48rem;
}

.am-modal-body {
  font-size: 0.4rem;
}

.am-modal-button-group-h .am-modal-button {
  font-size: 0.48rem;
}

.am-modal-footer {
  margin: 0.266667rem 0;
}