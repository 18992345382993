.technicalDocument {
  padding-bottom: 0;
  position: relative;
}

.technicalDocument .technicalDocumentBox {
  padding: 0.426667rem;
}

.technicalDocument .technicalDocumentBox>div {
  margin-bottom: 0.426667rem;
  padding: 0.32rem;
  background: #fff;
  border-radius: 0.106667rem;
}

.technicalDocument .technicalDocumentBox>div h3 {
  font-size: 0.373333rem;
  padding: 0.133333rem 0;
}

.technicalDocument .technicalDocumentBox>div ul {
  padding-left: 0.32rem;
}

.technicalDocument .technicalDocumentBox>div ul li {
  font-size: 0.373333rem;
  padding: 0.133333rem 0;
}

.technicalDocument .technicalDocumentBox>div ul li a {
  word-break: break-all;
}