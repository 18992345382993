.scrollBoxMain {
  height: 100%;
  position: relative;
}

.scrollBoxMain .scrollBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.scrollBoxMain .scrollBoxMainToast {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 1.066667rem;
  line-height: 1.066667rem;
  text-align: center;
}