.drawerBox .drawerBody {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 1.173333rem;
  right: 0;
  background-color: rgba(0,0,0,.2);
  z-index: 100;
}

.drawerBox .drawerMain {
  overflow: hidden;
  position: absolute;
  background-color: #fff;
  width: 60%;
  bottom: 0;
  top: 0;
}

.drawerBox .drawerMainLeftIn {
  left: 0 !important;
}

.drawerBox .drawerMainRightIn {
  right: 0 !important;
}

.drawerBox .drawerMainLeft,
.drawerBox .drawerMainRight {
  top: 0;
}

.drawerBox .drawerMainBottomIn {
  top: 50% !important;
}

.drawerBox .drawerMainOpenLeft {
  left: 0;
}

.drawerBox .propsChildrenBox {
  width: 100%;
  height: 100%;
}