.weeklyCalendarBox .weeklyCalendarBox-header ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  padding: 0 0.32rem;
}

.weeklyCalendarBox .weeklyCalendarBox-header ul li {
  width: 1.333333rem;
  height: 0.586667rem;
  font-family: PingFangSC-Regular;
  font-size: 0.32rem;
  color: #999;
  letter-spacing: 0;
  text-align: center;
  line-height: 0.586667rem;
  background-color: #f4f4f4;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.weeklyCalendarBox .weeklyCalendarBox-body ul li {
  height: 1.466667rem;
  line-height: 1.466667rem;
}

.weeklyCalendarBox .weeklyCalendarBox-body ul li .weeklyCalendarBox-item-active {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  line-height: 0.8rem;
  color: #fff;
  border-radius: 50% 50%;
  background-color: #0092ff;
}