.shouquan {
  padding: 0 0.533333rem;
  padding-top: 2.666667rem;
}

.shouquan h3 {
  text-align: center;
  font-size: 0.48rem;
  padding-bottom: 1.333333rem;
}

.shouquan .buttonBox {
  display: flex;
}

.shouquan .buttonBox>div {
  flex: 1 1;
}