.HomeBox .headerBox {
  background-image: url(../../static/images/headerBg.jpeg);
  background-repeat: no-repeat;
  background-size: 100% 4.453333rem;
  height: 6.586667rem;
  padding-top: 1.92rem;
}

.HomeBox .headerBox img {
  width: 9.173333rem;
  height: 4.666667rem;
  display: block;
  margin: 0 auto;
}

.HomeBox ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0.4rem;
  margin: 0.4rem 0;
}

.HomeBox ul li {
  background: #fff;
  box-shadow: 0 0.053333rem 0.106667rem 0 rgba(0,0,0,.1);
  border-radius: 0.133333rem;
  border-radius: 0.133333rem;
  margin-bottom: 0.266667rem;
  width: 2.88rem;
  height: 2.88rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.HomeBox ul li img {
  width: 0.893333rem;
  height: 0.933333rem;
  margin-bottom: 0.293333rem;
  display: block;
}

.HomeBox ul li span {
  font-family: PingFangSC-Regular;
  color: #333;
  line-height: 0.32rem;
}

.HomeBox ul li a {
  display: block;
  width: 2.88rem;
  height: 2.88rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.HomeBox ul li:last-child {
  opacity: 0;
}