.combined-wrapper {
  padding: 0;
  background: linear-gradient(135deg, #329fff 1%, #8c00ff 100%);
  height: 6.666667rem;
  position: relative;
  width: 100%;
}

.combined-reload {
  position: absolute;
  bottom: 0.213333rem;
  right: 0.213333rem;
}

.combined {
  color: #fff;
  width: 5.813333rem;
  height: 1.333333rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.combined-shape {
  position: relative;
}

.combined-shape>* {
  width: 50%;
  height: 0.853333rem;
  position: absolute;
  overflow: hidden;
}

.combined-shape>*>* {
  width: 100%;
  height: 100%;
}

.combined-shape .shape-left {
  left: 0;
}

.combined-shape .shape-right {
  right: 0;
}

.combined .title {
  font-size: 0.64rem;
  letter-spacing: 0.106667rem;
  position: absolute;
  width: 5.813333rem;
  overflow: hidden;
  font-family: Verdana,Geneva,Tahoma,sans-serif;
}

.combined-bar {
  width: 100%;
  height: 0.026667rem;
  background: #fff;
  position: absolute;
  top: 0.933333rem;
}

.combined .content {
  font-size: 0.24rem;
  text-align: center;
  position: absolute;
  top: 1.04rem;
}