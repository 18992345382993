.myLiftEditBox {
  background-color: #fff;
  overflow-y: auto;
}

.myLiftEditBox .coverBox {
  height: 0.266667rem;
  background: #f8f8f8;
}

.myLiftEditBox h3 {
  padding: 0.4rem;
  padding-bottom: 0;
  padding-left: 0.533333rem;
  font-family: PingFangSC-Medium;
  color: #333;
  position: relative;
  margin-bottom: 0.4rem;
}

.myLiftEditBox h3:after {
  width: 0.053333rem;
  content: " ";
  height: 0.373333rem;
  background: #0092ff;
  position: absolute;
  left: 0.4rem;
  top: 0.4rem;
}

.myLiftEditBox ul {
  padding: 0 0.4rem;
  padding-bottom: 0.4rem;
}

.myLiftEditBox ul li {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.266667rem;
  height: 0.8rem;
  align-items: center;
}

.myLiftEditBox ul li span {
  font-family: PingFangSC-Regular;
  color: #999;
  flex: 1 1;
}

.myLiftEditBox ul li span:first-child {
  color: #333;
  flex: .3 1;
}

.myLiftEditBox ul li .am-list-item {
  width: 100%;
  padding: 0;
}

.myLiftEditBox ul li .am-list-item .am-list-line {
  width: 100%;
}

.myLiftEditBox ul li .am-list-item .am-list-line .am-list-content {
  flex: .3 1;
}

.myLiftEditBox ul li .am-list-line {
  width: 100%;
}

.myLiftEditBox ul li .am-list-item.am-input-item {
  padding: 0;
  width: 100%;
}

.myLiftEditBox ul li .am-list-item .am-input-label.am-input-label-5 {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular;
  flex: .3 1;
}

.myLiftEditBox ul li .am-list-item .am-input-control input {
  font-size: 0.32rem;
}

.myLiftEditBox ul li .am-list-item .am-input-label {
  margin: 0;
}

.myLiftEditBox ul li .chooseTime {
  flex: 1 1;
}

.myLiftEditBox ul li .chooseTime>div {
  width: 100%;
}

.myLiftEditBox ul li .chooseTime .am-list-line {
  display: flex;
}

.myLiftEditBox ul li .chooseTime .am-list-line .am-list-extra {
  flex: 1 1;
  text-align: left;
}

.myLiftEditBox ul li .selectMain {
  flex: 1 1;
}