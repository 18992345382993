.documentListTwoBox {
  padding: 0.4rem 0;
  padding-bottom: 0;
  position: relative;
}

.documentListTwoBox-main {
  padding: 0.426667rem;
}

.documentListTwoBox-main>div {
  margin-bottom: 0.426667rem;
  padding: 0.32rem;
  background: #fff;
  border-radius: 0.106667rem;
}

.documentListTwoBox-main>div h3 {
  font-size: 0.373333rem;
  padding: 0.133333rem 0;
}

.documentListTwoBox-main>div ul {
  padding-left: 0.32rem;
}

.documentListTwoBox-main>div ul li {
  font-size: 0.373333rem;
  padding: 0.133333rem 0;
}

.documentListTwoBox-main>div ul li a {
  word-break: break-all;
}

.documentListTwoBox .documentListTwoBoxScrollBox {
  height: 100%;
}

.documentListTwoBox .my-drawer {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.documentListTwoBox .my-drawer .am-drawer-sidebar {
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.documentListTwoBox .my-drawer .am-drawer-sidebar .am-list {
  width: 4rem;
  padding: 0;
}

.documentListTwoBox .searchBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.documentListTwoBox .searchBox .searchInput.am-list-item {
  flex: 1 1;
}

.documentListTwoBox .searchBox .searchInput.am-list-item .am-input-control input {
  height: 2.666667rem;
}