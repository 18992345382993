.workOrderDetails {
  overflow-y: auto;
  height: 100%;
  background-color: #fff;
  padding-bottom: 2.213333rem;
}

.workOrderDetails .top {
  padding: 0.4rem;
  padding-bottom: 0;
}

.workOrderDetails .top li {
  margin-bottom: 0.266667rem;
  font-size: 0.373333rem;
  color: #333;
  font-family: PingFangSC-Regular;
}

.workOrderDetails .top li span {
  margin-left: 0.266667rem;
  color: #999;
}

.workOrderDetails .top li:last-of-type span {
  color: #0092ff;
}

.workOrderDetails .cover {
  height: 0.266667rem;
  background-color: #f8f8f8;
}

.workOrderDetails .bottom .am-tabs-default-bar-tab {
  font-family: PingFangSC-Regular;
  font-size: 0.373333rem;
  color: #333;
  text-align: center;
}

.workOrderDetails .bottom .am-tabs-default-bar-top .am-tabs-default-bar-tab,
.workOrderDetails .bottom .am-tabs-default-bar-bottom .am-tabs-default-bar-tab {
  padding: 0.333333rem 0;
}

.workOrderDetails .bottom ul.bottomItem {
  display: flex;
  margin: 0.266667rem 0;
  margin-left: 0.48rem;
  margin-bottom: 0;
}

.workOrderDetails .bottom ul.bottomItem span {
  font-family: PingFangSC-Regular;
  color: #666;
}

.workOrderDetails .bottom ul.bottomItem li {
  display: flex;
  align-items: center;
  margin-left: 0.4rem;
}

.workOrderDetails .bottom ul.bottomItem i {
  display: inline-block;
  width: 0.266667rem;
  height: 0.266667rem;
  border-radius: 50% 50%;
  margin-right: 0.133333rem;
}

.workOrderDetails .bottom ul.bottomItem li:first-of-type {
  margin-left: 0;
}

.workOrderDetails .bottom ul.bottomItem li:first-of-type i {
  background-color: #6dd400;
}

.workOrderDetails .bottom ul.bottomItem li:nth-of-type(2) i {
  background-color: #e02020;
}

.workOrderDetails .bottom ul.bottomItem li:nth-of-type(3) i {
  background-color: #32c5ff;
}

.workOrderDetails .bottom ul.bottomItem li:nth-of-type(4) i {
  background-color: #fa6400;
}

.workOrderDetails .bottom ul.bottomItem li:last-of-type i {
  background-color: #6236ff;
}

.workOrderDetails .am-tabs-default-bar-underline {
  border: none !important;
  height: 0.08rem;
}

.workOrderDetails .am-tabs-default-bar-underline::after {
  position: absolute;
  content: " ";
  height: 0.08rem;
  left: 25%;
  right: 25%;
  bottom: 0;
  background: #0092ff;
  border-radius: 0.08rem;
}

.workOrderDetails .listItemBox {
  height: 1.053333rem;
  margin: 0 0.4rem;
  border-bottom: 0.026667rem solid #e6e6e6;
}

.workOrderDetails .listItemBox>div {
  height: 100%;
  display: flex;
  align-items: center;
}

.workOrderDetails .listItemBox>div span:nth-of-type(2) {
  margin-left: 0.4rem;
  flex: 1 1;
}

.workOrderDetails .listItemBox>div i {
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
  border-radius: 50% 50%;
  border: 0.013333rem solid #999;
}

.workOrderDetails .listItemBox>div i.active1 {
  background-color: #6dd400;
}

.workOrderDetails .listItemBox>div i.active2 {
  background-color: #e02020;
}

.workOrderDetails .listItemBox>div i.active3 {
  background-color: #32c5ff;
}

.workOrderDetails .listItemBox>div i.active4 {
  background-color: #fa6400;
}

.workOrderDetails .listItemBox>div i.active5 {
  background-color: #6236ff;
}

.workOrderDetails .listItemBox>div>div {
  height: 100%;
  width: 1.333333rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.workOrderDetails .textAreaBox {
  padding: 0 0.4rem;
}

.workOrderDetails .textAreaBox textarea {
  width: 100%;
  padding: 0.133333rem;
  min-height: 2rem;
  line-height: 0.426667rem;
}

.workOrderDetails .textAreaBox h6 {
  margin: 0.266667rem 0;
}

.workOrderDetails h6 {
  font-family: PingFangSC-Regular;
  font-size: 0.373333rem;
  color: #333;
  font-weight: normal;
}

.workOrderDetails .mainSwiperBox {
  min-height: 5.066667rem;
}

.workOrderDetails .fileUploadBox {
  padding: 0 0.4rem;
}

.workOrderDetails .fileUploadBox .displayClose .am-image-picker-list .am-image-picker-item .am-image-picker-item-remove {
  display: none;
}

.workOrderDetails .fileUploadBox .am-image-picker-list .am-image-picker-item .am-image-picker-item-remove {
  width: 0.333333rem;
  height: 0.333333rem;
  background-size: 100%;
}

.workOrderDetails .fileUploadBox h6 {
  margin: 0.4rem 0;
}

.workOrderDetails .fileUploadBox .am-image-picker-list {
  margin: 0;
  padding: 0;
}

.workOrderDetails .fileUploadBox .signatureImage {
  width: 100%;
}

.workOrderDetails .footer {
  background: #fff;
  box-shadow: 0 0 0.266667rem 0 rgba(0,146,255,.1);
  height: 1.866667rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workOrderDetails .footer div {
  width: 4.52rem;
  height: 1.2rem;
  border: 0.026667rem solid #0092ff;
  border-radius: 0.133333rem;
  border-radius: 0.133333rem;
  background: #fff;
  line-height: 1.2rem;
  font-family: PingFangSC-Regular;
  font-size: 0.48rem;
  color: #0092ff;
  letter-spacing: 0;
  text-align: center;
}

.workOrderDetails .footer div:last-of-type {
  background: #0092ff;
  color: #fff;
  margin-left: 0.32rem;
}

.workOrderDetails .drawerMainBottomIn {
  top: 60% !important;
}