.signatureBox {
  position: relative;
  height: 100%;
}

.signatureBox .signatureFooter {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.signatureBox .signatureFooter span {
  display: block;
  width: 50%;
  height: 1.2rem;
  border: 0.026667rem solid #0092ff;
  background: #fff;
  line-height: 1.2rem;
  font-family: PingFangSC-Regular;
  font-size: 0.48rem;
  color: #0092ff;
  letter-spacing: 0;
  text-align: center;
}

.signatureBox .signatureFooter span:last-of-type {
  background: #0092ff;
  color: #fff;
}

.signatureBox .signatureBoxTitle {
  font-size: 0.373333rem;
  text-align: center;
  padding: 0.266667rem 0;
  border-bottom: 0.013333rem solid #e6e6ee;
}