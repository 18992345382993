.loginBox .am-list-item .am-input-label {
  font-size: 0.373333rem;
}

.loginBox .am-list-item .am-input-control input {
  font-size: 0.373333rem;
}

.loginBox .inputBox {
  padding: 0 0.4rem;
  padding-top: 2.666667rem;
  position: relative;
  z-index: 1000;
}

.loginBox .am-list-item .am-input-control {
  font-size: 0.373333rem;
}

.loginBox .button {
  margin-top: 0.266667rem;
}

.loginBox .am-list-item.am-input-item {
  height: 1.173333rem;
}

.loginBox .am-input-control {
  padding: 0 0.266667rem;
}

.loginBox .am-button {
  height: 1.173333rem;
  line-height: 1.173333rem;
}