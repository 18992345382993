.annualInspectionDue {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 2.213333rem;
  background-color: #fff;
}

.annualInspectionDue .top {
  padding: 0.4rem;
  padding-bottom: 0;
}

.annualInspectionDue .top li {
  margin-bottom: 0.266667rem;
  font-size: 0.373333rem;
  color: #333;
  font-family: PingFangSC-Regular;
}

.annualInspectionDue .top li span {
  margin-left: 0.266667rem;
  color: #999;
}

.annualInspectionDue .am-list-item {
  padding-left: 0;
}

.annualInspectionDue .am-list-item .am-list-line {
  padding-right: 0;
}

.annualInspectionDue .am-list-item .am-list-line .am-list-content {
  font-size: 0.373333rem;
}

.annualInspectionDue .am-list-item .am-list-line .am-list-extra {
  font-size: 0.373333rem;
}

.annualInspectionDue .am-list-item .am-list-line .am-list-arrow {
  width: 0.32rem;
  height: 0.32rem;
}

.annualInspectionDue h1 {
  font-family: PingFangSC-Medium;
  font-size: 0.373333rem;
  color: #333;
  position: relative;
  padding: 0.333333rem 0;
  padding-left: 0.533333rem;
  background-color: #f8f8f8;
}

.annualInspectionDue h1:after {
  width: 0.053333rem;
  content: " ";
  height: 0.373333rem;
  background: #0092ff;
  position: absolute;
  left: 0.4rem;
  top: 0.373333rem;
}

.annualInspectionDue .information {
  padding: 0 0.4rem;
}

.annualInspectionDue .information .information-item {
  border-bottom: 0.013333rem solid #e6e6e6;
  padding: 0.266667rem 0;
}

.annualInspectionDue .information .information-item .am-list-item .am-input-control input {
  text-align: right;
}

.annualInspectionDue .information .information-item .am-list-item .am-input-label.am-input-label-5 {
  width: 1.6rem;
}

.annualInspectionDue .information .information-item .am-list-body::before {
  display: none;
}

.annualInspectionDue .information .information-item .am-list-body::after {
  display: none;
}

.annualInspectionDue .information .information-item-point {
  font-size: 0.373333rem;
  display: flex;
  justify-content: space-between;
}

.annualInspectionDue .information .information-item-point .faultPoint {
  width: 5.333333rem;
  text-align: right;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.annualInspectionDue .textAreaBox textarea {
  width: 100%;
  padding: 0.133333rem;
  min-height: 2rem;
  line-height: 0.426667rem;
}

.annualInspectionDue .textAreaBox h6 {
  margin: 0.266667rem 0;
}

.annualInspectionDue h6 {
  font-family: PingFangSC-Regular;
  font-size: 0.373333rem;
  color: #333;
  font-weight: normal;
  margin: 0.266667rem 0;
}

.annualInspectionDue .drawerMainBottomIn {
  top: 10% !important;
}

.annualInspectionDue .faultPointIdBox {
  height: 100%;
  width: 100%;
  padding: 0.4rem;
}

.annualInspectionDue .faultPointIdBox .treeItemName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.066667rem 0;
  margin: 0.066667rem 0;
}

.annualInspectionDue .faultPointIdBox .treeItemName img {
  width: 0.333333rem;
  height: 0.333333rem;
}

.annualInspectionDue .faultPointIdBox .treeItemNameActive {
  color: #0092ff;
}

.annualInspectionDue .faultPointIdBox .treeitem>.group {
  padding-left: 0.4rem;
}

.annualInspectionDue .footer {
  background: #fff;
  box-shadow: 0 0 0.266667rem 0 rgba(0,146,255,.1);
  height: 1.866667rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.annualInspectionDue .footer div {
  width: 4.52rem;
  height: 1.2rem;
  border: 0.026667rem solid #0092ff;
  border-radius: 0.133333rem;
  border-radius: 0.133333rem;
  background: #fff;
  line-height: 1.2rem;
  font-family: PingFangSC-Regular;
  font-size: 0.48rem;
  color: #0092ff;
  letter-spacing: 0;
  text-align: center;
}

.annualInspectionDue .footer div:last-of-type {
  background: #0092ff;
  color: #fff;
  margin-left: 0.32rem;
}

.annualInspectionDue .drawerMainBottomIn:last-child {
  top: 60% !important;
}

.annualInspectionDue .am-list-item {
  max-height: auto;
  min-height: auto;
}

.annualInspectionDue .am-list-line {
  position: relative;
}

.annualInspectionDue .am-list-line::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.013333rem;
  background-color: #e6e6e6;
  content: " ";
}

.annualInspectionDue .displayClose .am-image-picker-item-remove {
  display: none;
}

.annualInspectionDue .moreAddBox>div {
  display: flex;
  margin-bottom: 0.266667rem;
}

.annualInspectionDue .moreAddBox>div>input {
  margin: 0 0.266667rem;
}

.annualInspectionDue .moreAddBox>div>a {
  width: 2.666667rem;
  text-align: center;
  margin: 0 0.266667rem;
  margin-top: 0.133333rem;
}

.annualInspectionDue .moreAddBox>div>span {
  margin-top: 0.133333rem;
  display: inline-block;
  width: 0.533333rem;
}