.myLiftBox {
  padding: 0.4rem 0;
  padding-bottom: 0;
  position: relative;
}

.myLiftBox .myLiftBoxScrollBox {
  height: 100%;
}

.myLiftBox .my-drawer {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.myLiftBox .my-drawer .am-drawer-sidebar {
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.myLiftBox .my-drawer .am-drawer-sidebar .am-list {
  width: 4rem;
  padding: 0;
}

.myLiftBox .searchBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.myLiftBox .searchBox .searchInput.am-list-item {
  flex: 1 1;
}

.myLiftBox .searchBox .searchInput.am-list-item .am-input-control input {
  height: 2.666667rem;
}