.documentListBox {
  padding: 0.4rem 0;
  padding-bottom: 0;
  position: relative;
}

.documentListBox .documentListBoxScrollBox {
  height: 100%;
}

.documentListBox .documentListBoxScrollBox-ul {
  padding: 0 0.266667rem;
  display: grid;
  grid-row-gap: 0.266667rem;
  row-gap: 0.266667rem;
  grid-column-gap: 0.266667rem;
  column-gap: 0.266667rem;
  grid-template-columns: repeat(4, calc(25% - 0.2rem));
}

.documentListBox .documentListBoxScrollBox-ul li {
  text-align: center;
}

.documentListBox .documentListBoxScrollBox-ul li img {
  width: 100%;
}

.documentListBox .my-drawer {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.documentListBox .my-drawer .am-drawer-sidebar {
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.documentListBox .my-drawer .am-drawer-sidebar .am-list {
  width: 4rem;
  padding: 0;
}

.documentListBox .searchBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.documentListBox .searchBox .searchInput.am-list-item {
  flex: 1 1;
}

.documentListBox .searchBox .searchInput.am-list-item .am-input-control input {
  height: 2.666667rem;
}