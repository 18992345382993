.emergencyRescue {
  padding: 0.4rem;
}

.emergencyRescue .emergencyRescueItem {
  background: #fff;
  box-shadow: 0 0.053333rem 0.106667rem 0 rgba(0,0,0,.1);
  border-radius: 0.133333rem;
  border-radius: 0.133333rem;
  padding: 0.2rem;
}

.emergencyRescue .emergencyRescueItem>div:first-of-type {
  border-bottom: 0.013333rem solid #ccc;
}

.emergencyRescue .emergencyRescueItem p {
  padding: 0.2rem;
  font-family: PingFangSC-Regular;
  font-size: 0.346667rem;
  color: #333;
  letter-spacing: 0;
}

.emergencyRescue .emergencyRescueItem p span {
  margin-right: 0.133333rem;
}

.emergencyRescue .emergencyRescueItem p span:nth-of-type(2) {
  color: #999;
}

.emergencyRescue .emergencyRescueItem .emergencyRescuePhone a {
  color: #0092ff;
}

.emergencyRescue .emergencyRescuePrompt {
  margin-top: 0.4rem;
  color: #999;
}

.emergencyRescue .emergencyRescuePrompt h5 {
  margin-bottom: 0.133333rem;
  font-size: 0.32rem;
}

.emergencyRescue .emergencyRescuePrompt p {
  margin-bottom: 0.133333rem;
}